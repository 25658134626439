import Vue from 'vue';

const store = Vue.observable({
  query: {},
});

const saveInStore = $route => {
  if (!($route && $route.query)) {
    return;
  }

  const query = { ...$route.query };
  if (Object.keys(query).length) {
    store.query = query;
  }
};

function removeExpiredTokenEmail(query, unnecessaryQuery) {
  if (unnecessaryQuery !== 'cause' || !query.email) {
    return;
  }
  if (query.cause === 'expired-token') {
    delete query.email;
    delete query.cognito;
  }
}

const removeFromUrl = (vueApp, { routerName, unnecessaryQuery }) => {
  if (!(vueApp.$route && vueApp.$route.query) || !vueApp.$router) {
    return;
  }
  const query = { ...vueApp.$route.query };
  if (query && query[unnecessaryQuery] && routerName) {
    removeExpiredTokenEmail(query, unnecessaryQuery);
    delete query[unnecessaryQuery];

    vueApp.$router.replace({
      name: routerName,
      query,
    });
  }
};

export { store, saveInStore, removeFromUrl };
