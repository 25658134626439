import { sanitizeUrl } from '@contaazul/sanitize-url';
import { cognitoStore } from '@/services/cognitoStore';
import getOriginURL from './getOriginURL';

const redirectToProduct = async (route, email) => {
  if (cognitoStore.userType === 'ACC') {
    // TODO: redirect to ACC
    window.location.href = 'https://contabilidade.contaazul.com';
  } else {
    const originURL = await getOriginURL(route, email);
    window.location.href = sanitizeUrl(originURL);
  }
};

export default redirectToProduct;
