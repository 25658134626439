<template>
  <div>
    <ds-row>
      <ds-col>
        <ds-heading :text="textHeading" />
      </ds-col>
    </ds-row>
    <ds-row>
      <ds-col>
        <ds-p v-if="isTotpMfa">
          Para ajudar a proteger sua conta, digite abaixo o
          <b>código de 6 dígitos</b> gerado pelo seu aplicativo de autenticação:
        </ds-p>
        <div v-else>
          <ds-p>
            Olá, <b>{{ obfuscatedEmail }}</b
            >!
          </ds-p>
          <ds-p>
            {{ smsTextComplete() }}
          </ds-p>
        </div>
      </ds-col>
    </ds-row>
    <ds-row v-if="phone" content-vertical-align="center" vertical-offset="2">
      <ds-col :size="2">
        <ds-avatar size="xs" alt="Avatar do Usuário" />
      </ds-col>
      <ds-col size="8">
        <div>
          <ds-text>
            <!-- TODO: change phone value to obfuscate value when component supports  -->
            <b>{{ phone }}</b>
          </ds-text>
        </div>
        <div>
          <ds-link @click="changePhone"> Alterar </ds-link>
        </div>
      </ds-col>
    </ds-row>
    <ds-row vertical-offset="2">
      <ds-col>
        <ds-form
          :submit-action="confirmSignin"
          @submit-error="onConfirmSigninError"
          @submit-success="onConfirmSigninSuccess">
          <ds-field label="Digite o código" :icon-tooltip="iconTooltipText">
            <ds-input v-model="verificationCode" :custom-validations="customValidations" maxlength="6" required />
          </ds-field>
          <ds-field>
            <ds-checkbox v-model="rememberDevice" label="Não pergunte novamente neste dispositivo" />
          </ds-field>
          <ds-row>
            <ds-col size="12">
              <ds-submit-button full-width> Autenticar </ds-submit-button>
            </ds-col>
          </ds-row>
          <ds-row v-if="!isTotpMfa" justify="center">
            <ds-col size="6" align="center">
              <ds-button
                v-ds-tooltip.bottom="bottomTooltipText"
                theme="link"
                :disabled="shouldDisabledResendCode"
                @click="resendCode">
                Reenviar Código
              </ds-button>
              <ds-text v-if="shouldDisabledResendCode" weight="bold"> {{ timerDescription }} </ds-text>
            </ds-col>
          </ds-row>
          <ds-row justify="center">
            <ds-col size="3" align="center">
              <ds-external-link
                with-icon
                href="https://ajuda.contaazul.com/hc/pt-br/articles/29302562127117-Conta-Azul-Pro-acesso-com-autentica%C3%A7%C3%A3o-de-dois-fatores">
                Ajuda
              </ds-external-link>
            </ds-col>
          </ds-row>
        </ds-form>
      </ds-col>
    </ds-row>
  </div>
</template>

<script>
import { sanitizeUrl } from '@contaazul/sanitize-url';
import { obfuscateString } from '@/services/obfuscateStringService';
import { toasterService } from '@contaazul/design-system';
import { amplifyConfirmSignin } from '@/services/confirmSignInService';
import {
  triedToConfirmMfaSigninTrack,
  resendCodeConfirmMfaSigninTrack,
  triedToChangePhoneMfaSigninTrack,
} from '@/services/authenticationTrack/authenticationTrackService';
import { Buffer } from 'buffer';
import { EMAIL_ORIGIN_RESEND_CODE } from '../../utils/constants';

export default {
  name: 'MfaSigninVerification',
  data() {
    return {
      phone: '',
      email: '',
      app: '',
      redirect: '',
      verificationCode: null,
      user: null,
      customValidations: [
        {
          message: 'O código precisa conter 6 digitos',
          valid(value) {
            return value.length === 6;
          },
        },
      ],
      rememberDevice: true,
      countdown: null,
      shouldDisabledResendCode: false,
      timerDescription: '',
      isTotpMfa: false,
    };
  },
  computed: {
    obfuscatedEmail() {
      return this.email ? obfuscateString(this.email, 3, 5) : '';
    },
    textHeading() {
      return this.isTotpMfa ? 'Confira o código no seu aplicativo de autenticação.' : 'Verificação em Duas Etapas';
    },
    bottomTooltipText() {
      return this.shouldDisabledResendCode
        ? 'Por favor, aguarde 5 minutos para solicitar o reenvio de um novo código'
        : '';
    },
    iconTooltipText() {
      return this.isTotpMfa
        ? 'O código de verificação é gerado através de um aplicativo de autenticação da sua preferência'
        : '';
    },
  },
  mounted() {
    const { email, phone = '', app = '', redirect = '' } = this.$route.query;

    this.email = this.convertBase64ToAscii(email);
    this.app = app;
    this.redirect = redirect;

    const { user } = this.$route.params;

    this.isTotpMfa = user?.challengeName === 'SOFTWARE_TOKEN_MFA';
    if (!this.isTotpMfa) {
      this.phone = this.convertBase64ToAscii(phone);
    }

    if (!user) {
      this.$router.push({ name: 'Login' });
      return;
    }

    this.user = user;
    this.startCountdown();
  },
  methods: {
    smsTextComplete() {
      return `Uma mensagem de texto com um código de verificação de 6 dígitos acabou de ser enviada
        ${this.phone ? 'para seu número de telefone:' : 'para o número de telefone cadastrado na sua conta.'}`;
    },
    confirmSignin() {
      triedToConfirmMfaSigninTrack({
        email: this.email,
        properties: {
          data: this.user,
          rememberDevice: this.rememberDevice,
        },
      });
      return amplifyConfirmSignin(this.user, this.verificationCode, this.rememberDevice, this.app);
    },
    onConfirmSigninSuccess(data) {
      if (this.redirect) {
        window.location.href = sanitizeUrl(this.redirect);
        return;
      }
      window.location.href = sanitizeUrl(data.location);
    },
    onConfirmSigninError(error) {
      if (error.code === 'NotAuthorizedException') {
        toasterService.error({
          title: 'Sessão expirada!',
          content: 'Ocorreu uma falha ao autenticar. Informe novamente suas credencias de acesso a plataforma',
        });
        this.$router.push({ name: 'Login' });
        return;
      }
      toasterService.error({ title: error.title, content: error.detail });
    },
    resendCode() {
      resendCodeConfirmMfaSigninTrack({
        email: this.email,
      });

      this.$router.push({
        name: 'Login',
        params: {
          email: this.email,
          emailOrigin: EMAIL_ORIGIN_RESEND_CODE,
        },
        query: {
          redirect: this.redirect,
        },
      });
    },
    changePhone() {
      triedToChangePhoneMfaSigninTrack({
        email: this.email,
      });
      const params = {
        phone: this.convertToBase64(this.phone),
        email: this.convertToBase64(this.email),
      };

      if (this.redirect) {
        params.redirect = this.redirect;
      }

      this.$router.push({
        name: 'MfAChangePhonePage',
        params,
      });
    },
    paddedFormat(num) {
      return num < 10 ? `0${num}` : num;
    },
    updateTimerDescription(seconds) {
      const min = parseInt(seconds / 60);
      const sec = parseInt(seconds % 60);

      this.timerDescription = `${this.paddedFormat(min)}:${this.paddedFormat(sec)}`;
    },
    finishCountdown() {
      clearInterval(this.countdown);
      this.shouldDisabledResendCode = false;
    },
    startCountdown() {
      if (this.isTotpMfa) return;

      this.shouldDisabledResendCode = true;
      const lastSendCode = new Date(Date.now() + 5 * 60000).getTime();
      const timeNow = new Date(Date.now()).getTime();
      const diff = lastSendCode - timeNow;
      if (diff > 0) {
        let secondsRemaining = diff / 1000;
        this.updateTimerDescription(secondsRemaining);
        this.countdown = setInterval(() => {
          if (--secondsRemaining < 0) {
            this.finishCountdown();
          }
          this.updateTimerDescription(secondsRemaining);
        }, 1000);
      }
    },
    convertBase64ToAscii(param) {
      return Buffer.from(param, `base64`).toString(`ascii`);
    },
    convertToBase64(param) {
      return Buffer.from(param).toString(`base64`);
    },
  },
};
</script>
