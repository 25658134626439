export const AUTH_TYPE = {
  heimdall: 'HEIMDALL',
  cognito: 'COGNITO',
};

export const OUTCOME = {
  success: 'SUCCESS',
  error: 'ERROR',
};

export const PAGES = {
  login: 'LOGIN',
  mfaSigninVerification: 'MFA_SIGNIN_VERIFICATION',
  totpConfiguration: 'TOTP_CONFIGURATION',
  passwordRecovery: 'PASSWORD_RECOVERY',
  forceChangePassword: 'FORCE_CHANGE_PASSWORD',
};

export const ERROR_TYPES = {
  notAuthorizedCode: 'NotAuthorizedException',
  notAuthorizedMessage: 'Request failed with status code 401',
};

export const LOGIN_ACTIONS = {
  expiredTokenRedirect: 'EXPIRED_TOKEN_REDIRECT',
  triedToLogin: 'TRIED_TO_LOGIN',
  errorLogin: 'ERROR_LOGIN',
  notAuthorized: 'NOT_AUTHORIZED',
  successLogin: 'SUCCESS_LOGIN',
};

export const MFA_CONFIRM_SIGNIN_ACTIONS = {
  triedToConfirm: 'TRIED_TO_CONFIRM_SIGNIN',
  errorConfirm: 'ERROR_CONFIRM_SIGNIN',
  successConfirm: 'SUCCESS_CONFIRM_SIGNIN',
  resendCode: 'RESEND_CODE',
  triedToChangePhone: 'TRIED_TO_CHANGE_PHONE_SIGNIN',
};

export const MFA_TOTP_CONFIGURATION_ACTIONS = {
  triedToSetup: 'TRIED_TO_SETUP_TOTP',
  errorSetup: 'ERROR_SETUP_TOTP',
  successSetup: 'SUCCESS_SETUP_TOTP',
  triedToValidateCode: 'TRIED_TO_VALIDATE_TOTP_CODE',
  errorValidateCode: 'ERROR_VALIDATE_TOTP_CODE',
  successValidateCode: 'SUCCESS_VALIDATE_TOTP_CODE',
};

export const PASSWORD_RECOVERY_ACTIONS = {
  triedToRecovery: 'TRIED_TO_RECOVERY_PASSWORD',
  errorRecovery: 'ERROR_RECOVERY_PASSWORD',
  successRecovery: 'SUCCESS_RECOVERY_PASSWORD',
  triedToResendEmail: 'TRIED_TO_RESEND_EMAIL',
  errorResendEmail: 'ERROR_RESEND_EMAIL',
  successResendEmail: 'SUCCESS_RESEND_EMAIL',
  openChangePasswordPage: 'OPEN_CHANGE_PASSWORD_PAGE',
  triedToChangePassword: 'TRIED_TO_CHANGE_PASSWORD',
  successChangePassword: 'SUCCESS_CHANGE_PASSWORD',
  errorChangePassword: 'ERROR_CHANGE_PASSWORD',
};

export const FORCE_CHANGE_PASSWORD_ACTIONS = {
  triedToForceChangePassword: 'TRIED_TO_FORCE_CHANGE_PASSWORD',
  errorForceChangePassword: 'ERROR_FORCE_CHANGE_PASSWORD',
  successForceChangePassword: 'SUCCESS_FORCE_CHANGE_PASSWORD',
};
