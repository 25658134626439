<template>
  <div>
    <ds-row>
      <ds-col class="ds-u-text-align--center">
        <ds-link @click="goToHome">
          <logo />
        </ds-link>
      </ds-col>
    </ds-row>
    <ds-row>
      <ds-col>
        <router-view></router-view>
      </ds-col>
    </ds-row>
  </div>
</template>

<script>
import Logo from '@/components/logo/Logo';

export default {
  name: 'ContentSide',
  components: {
    Logo,
  },
  methods: {
    goToHome() {
      this.$router.push({ path: '/' });
    },
  },
};
</script>
