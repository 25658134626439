<template>
  <ds-row>
    <ds-col v-for="alert in shownAlertMessages" :key="alert.id">
      <ds-box-information :theme="alert.content.theme" :title="alert.content.title" :message="alert.content.message" />
    </ds-col>
  </ds-row>
</template>

<script>
import * as urlQueryParamsHandler from '@/services/urlQueryParamsHandler';
import { successRedirectByExpiredTokenTrack } from '@/services/authenticationTrack/authenticationTrackService';

export default {
  name: 'AlertMessenger',
  props: {
    messagesModel: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      shownAlertMessages: [],
      localStorageKey: 'authenticationAlertMessagesClosed',
    };
  },
  created() {
    urlQueryParamsHandler.saveInStore(this.$route);
  },
  mounted() {
    urlQueryParamsHandler.removeFromUrl(this, {
      routerName: 'Login',
      unnecessaryQuery: 'cause',
    });
    this.setupAlertMessages();
  },
  methods: {
    setupAlertMessages() {
      this.applyRulesValidation(this.messagesModel).forEach(alertMessage => {
        this.trackExpiredTokenCause(alertMessage.id);
        this.shownAlertMessages.push(alertMessage);
      });
    },
    applyRulesValidation(messagesModel) {
      return messagesModel.filter(alertMessage => {
        if (!(alertMessage && alertMessage.rules)) {
          return false;
        }
        return this.alertRequestedByUrlParameter(alertMessage);
      });
    },
    alertRequestedByUrlParameter(alertMessage) {
      const alertMessageIdFromStore = this.getAlertMessageIdFromStore();
      return alertMessageIdFromStore === alertMessage.id;
    },
    trackExpiredTokenCause(cause) {
      const { email, cognito } = urlQueryParamsHandler.store.query;
      if (cause === 'expired-token') {
        successRedirectByExpiredTokenTrack({
          email,
          isCognito: cognito === 'true',
        });
      }
    },
    getAlertMessageIdFromStore() {
      return (urlQueryParamsHandler.store.query && urlQueryParamsHandler.store.query.cause) || null;
    },
  },
};
</script>
