const { Amplify, Auth } = require('aws-amplify');

function configure() {
  Amplify.configure({
    Auth: {
      region: process.env.VUE_APP_COGNITO_REGION,
      userPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID,
      userPoolWebClientId: process.env.VUE_APP_COGNITO_APP_CLIENT,
      authenticationFlowType: 'USER_SRP_AUTH',
    },
  });

  return Auth.configure();
}

function handleSetupTotp(user) {
  return Auth.setupTOTP(user).then(secret => ({ user, secret }));
}

export function signin(username, password) {
  configure();
  return Auth.signIn(username, password);
}

export function signOut() {
  configure();
  return Auth.signOut();
}

export function confirmSignInCode(user, code) {
  return Auth.confirmSignIn(user, code);
}

export function verifyUserAndSetupTotp() {
  return Auth.currentAuthenticatedUser()
    .then(user => handleSetupTotp(user))
    .catch(() => Promise.resolve({}));
}

export function getCurrentUser() {
  return Auth.currentAuthenticatedUser();
}

export function getPreferredMFA(user) {
  return Auth.getPreferredMFA(user);
}

export function verifyTotpToken(code) {
  return Auth.currentAuthenticatedUser().then(user => Auth.verifyTotpToken(user, code));
}

export function setPreferredMFA(user, preferredMFA) {
  return Auth.setPreferredMFA(user, preferredMFA);
}

export function forgotPassword(email) {
  configure();
  return Auth.forgotPassword(email);
}

export function forgotPasswordChange(email, code, password) {
  configure();
  return Auth.forgotPasswordSubmit(email, code, password);
}

export function completeNewPassword(user, password, requiredParams) {
  configure();
  return Auth.completeNewPassword(user, password, requiredParams);
}
