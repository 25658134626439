import getOriginURL from './getOriginURL';

export async function getProductLocationByUser({ app = '', email = '' } = {}) {
  if (app) {
    return getSandboxLocation(app);
  }

  const originUrl = await getOriginURL({}, email);
  return originUrl;
}

function getSandboxLocation(app) {
  return `http://${app}.dev.contaazul.local`;
}
