import { Buffer } from 'buffer';
import { checkCaProWebAB } from '@/resources/caProWebAbTestResource.js';

export async function hasCaProWeb(email) {
  if (!email) {
    return false;
  }

  const hash = Buffer.from(email).toString(`base64`);
  const { data: hasCaProWebAB } = await checkCaProWebAB(hash);

  return hasCaProWebAB;
}
