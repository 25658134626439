<template>
  <div>
    <ds-row>
      <ds-col>
        <ds-heading size="md" text="Ative a autenticação de dois fatores" />
      </ds-col>
      <ds-col>
        <ds-p>
          A autenticação de dois fatores aumenta a segurança da sua conta. O processo é obrigatório e por usuário. Para
          mais informações, acesse nosso artigo na
          <ds-external-link
            with-icon
            href="https://ajuda.contaazul.com/hc/pt-br/articles/29302562127117-Conta-Azul-Pro-acesso-com-autentica%C3%A7%C3%A3o-de-dois-fatores">
            central de ajuda
          </ds-external-link>
        </ds-p>
      </ds-col>
    </ds-row>
    <ds-row>
      <ds-col>
        <ds-async-content-wrapper :fetch-action="fetchAction" @fetch-success="onFetchSuccess">
          <ds-form
            :is-valid.sync="isFormValid"
            :submit-action="validateCode"
            @submit-success="onSuccessValidate"
            @submit-error="onErrorValidate">
            <ds-list theme="number-circle">
              <ds-list-item>
                Pegue seu smartphone. Se você ainda não tem um aplicativo de autenticação, recomendamos que instale o
                <b>Google Authenticator</b>.
              </ds-list-item>
              <ds-list-item>
                Abra o aplicativo, faça a leitura deste QR code ou insira o código.
                <ds-row justify="center" class="ds-u-margin-top--sm">
                  <ds-col size="auto">
                    <ds-qrcode :value="key.qrCode" />
                  </ds-col>
                </ds-row>
                <ds-row justify="center" class="ds-u-margin-bottom--none">
                  <ds-col size="12">
                    <ds-p>
                      {{ key.secret }}
                    </ds-p>
                  </ds-col>
                </ds-row>
                <ds-row justify="center">
                  <ds-col size="auto" class="ds-u-padding-top--none">
                    <ds-request-button
                      icon="copy"
                      icon-position="left"
                      size="sm"
                      :on-request="onCopyRequest"
                      @request-success="onCopySuccess"
                      @request-error="onCopyError">
                      Copiar código
                    </ds-request-button>
                  </ds-col>
                </ds-row>
              </ds-list-item>
              <ds-list-item>
                Insira abaixo o código de 6 dígitos gerado pelo seu aplicativo de autenticação:
                <ds-row class="ds-u-margin-top--sm">
                  <ds-col size="6">
                    <ds-field label="Código" class="ds-u-margin-bottom--none">
                      <ds-input
                        v-model="verificationCode"
                        :custom-validations="customValidations"
                        maxlength="6"
                        required />
                    </ds-field>
                  </ds-col>
                </ds-row>
                <ds-row>
                  <ds-col>
                    <ds-submit-button theme="primary" class="ds-u-margin-top--xs" :disabled="!isFormValid" full-width>
                      Continuar
                    </ds-submit-button>
                  </ds-col>
                </ds-row>
              </ds-list-item>
            </ds-list>
          </ds-form>
        </ds-async-content-wrapper>
      </ds-col>
    </ds-row>
  </div>
</template>

<script>
import copy from 'copy-to-clipboard';
import { sanitizeUrl } from '@contaazul/sanitize-url';
import { toasterService } from '@contaazul/design-system';
import { verifyUserAndSetupTotp } from '@/services/amplifyService';
import { confirmTotpToken } from '@/services/confirmTotpService';
import {
  triedToSetupTotpTrack,
  errorSetupTotpTrack,
  successSetupTotpTrack,
  triedToValidateTotpCodeTrack,
} from '@/services/authenticationTrack/authenticationTrackService';

export default {
  name: 'MfaTotpConfiguration',
  data() {
    return {
      isFormValid: null,
      redirect: '',
      email: null,
      user: null,
      key: {
        secret: '',
        qrCode: '',
      },
      verificationCode: '',
      customValidations: [
        {
          message: 'O código precisa conter 6 digitos',
          valid(value) {
            return value.length === 6;
          },
        },
      ],
    };
  },
  mounted() {
    const { redirect = '' } = this.$route.query;
    this.redirect = redirect;
  },
  methods: {
    fetchAction() {
      const { email } = this.$route.params;
      this.email = email;
      triedToSetupTotpTrack({
        email: this.email,
      });
      return verifyUserAndSetupTotp();
    },
    onFetchSuccess({ user, secret }) {
      if (!user || !secret) {
        errorSetupTotpTrack({
          email: this.email,
          properties: {
            step: !user ? 'errorOnGetUser' : 'errorOnGetSecret',
          },
        });
        this.$router.push({ name: 'Login' });
        return;
      }
      this.user = user;

      successSetupTotpTrack({
        email: this.user.username,
      });
      const qrCode = `otpauth://totp/ContaAzul:${user.username}?secret=${secret}&issuer=ContaAzul`;
      this.key = {
        secret,
        qrCode,
      };
    },
    onCopyRequest() {
      return copy(this.key.secret);
    },
    onCopySuccess() {
      toasterService.success({
        content: 'Copiado para a área de transferência!',
      });
    },
    onCopyError() {
      toasterService.warning({
        title: 'Erro ao copiar o QR code',
        content: 'Necessário permitir esta ação nas configurações do seu navegador.',
      });
    },
    validateCode() {
      triedToValidateTotpCodeTrack({
        email: this.user.username,
      });
      return confirmTotpToken(this.verificationCode, this.user);
    },
    onSuccessValidate(data) {
      if (this.redirect) {
        window.location.href = sanitizeUrl(this.redirect);
        return;
      }
      window.location.href = sanitizeUrl(data.location);
    },
    onErrorValidate(error) {
      toasterService.error({ title: error.title, content: error.detail });
    },
  },
};
</script>
