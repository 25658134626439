export function handleRequiredParams({ userAttributes, requiredAttributes }) {
  if (requiredAttributes.length === 0) {
    return {};
  }
  const attributes = requiredAttributes.reduce((acc, attribute) => {
    if (attribute === 'name') {
      acc.name = userAttributes.email.split('@')[0];
    }
    if (attribute === 'phone_number') {
      acc.phone_number = '+5599000000000';
    }
    return acc;
  }, {});

  return attributes;
}
